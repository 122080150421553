import * as React from "react"
import SEO from "../../components/seo";
import LayoutRechtsgebiete from "../../components/LayoutRechtsgebiete";

export default () => {

    return (
        <LayoutRechtsgebiete>
            <div id="content" className="animate-fade-in">
                <h1>Erbrecht</h1>
                <ul>
                    <li>Testament</li>
                    <li>Erbvertrag</li>
                    <li>Pflichtteilsrecht</li>
                    <li>Erbausschlagung</li>
                    <li>Erbanfechtung</li>
                    <li>Testamentvollstreckung</li>
                    <li>Erbengemeinschaft</li>
                </ul>
                <p>
                    Das Betätigungsfeld des Anwalts auf dem Gebiet des Erbrechts sollte vor dem Erbfall beginnen.
                    Ausgewogene und individuell abgestimmte erbvertragliche Lösungen, vorzeitige Vermögensübertragungen
                    als
                    Instrument einer vorausschauenden Nachfolgeplanung oder eine Abspaltung von Nachlassteilen durch
                    Verträge zu Gunsten Dritter auf dem Todesfall helfen, die durch einen Tod entstehende rechtliche
                    Unsicherheit zu vermeiden. Ich berate und vertrete Sie gerne in oben genannten Angelegenheiten.
                </p>
                <p>
                    Nach dem Eintritt des Erbfalls unterstützte ich Sie bei der Nachlassabwicklung, der
                    Auseinandersetzung
                    mit Miterben und Streit um Pflichtteils- und Erbschaftsansprüche. Dies umfasst insbesondere die
                    Beratung
                    über Ihre Möglichkeiten der der Annahme und Ausschlagung der Erbschaft und den damit verbundenen
                    Konsequenzen, das Erbschaftsverfahren und die Testamentsvollstreckung.
                </p>
            </div>
        </LayoutRechtsgebiete>
    )
};

export const Head = () => {
    return (<SEO title={'Erbrecht'}/>);
}
